body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  --border-gray: 0.12rem solid rgb(107, 107, 107);
  /* --button-green: #00ccff; */
  --button-green: #ff9e0c;
  --paragraph-gray: rgb(140,140,140);
  --main-black: #222222;
  --main-blue: #ff9e0c;
  --title-gray: rgb(106,106,106)

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  
}

.App > div{
  max-width: 100vw;
  overflow-x: hidden;
  /* position: fixed; */
}

.App{
  position: relative;
}

.home-container{
  max-width: 100vw;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 11fr;
  height: 100vh;
  display: grid;
  justify-content: center;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.home-menu-wrapper{
  position: relative;
  
}


.content-container{
  display: grid;
  position: relative;
  align-items: center;
  height: 88vh;
  /* border: 4px solid red; */
  max-width: 100vw;
  margin: 2rem;
  /* margin-right: 2rem; */
  /* margin: 2rem; */
  /* margin-bottom: -2rem; */
  padding: 2rem;
}

.image{
  display: none;
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.content-container-left{
  display: flex;
  align-items: center;
  /* padding: 6rem; */
  flex-direction: column;
  position: relative;
  height: 100%;
  z-index: 6;
  width: fit-content;
  gap: 2rem;
  justify-content: center;
  margin-top: -13.8vh;
  width: 35vw;
  padding: 2vw;
  /* max-width: 38vw; */
}

.content-container-left > button {
  width: 100%;
  padding: 1.2rem;
  border-radius: .4rem;
  border: none;
  outline: none;
  /* background-color: var(--main-blue); */
  background-image: linear-gradient(45deg, var(--main-blue), #e34646);
  color: white;
  font-weight: 900;
  font-size: 1.2rem;
  opacity: .5;
  transition: opacity .3s ease-in-out ;
  /* position: relative; */
  /* max-width: 40vw; */
}

.content-container-left > button:hover {
    /* background-color: white; */
    background-image: linear-gradient(220deg, var(--main-blue), #e34646);
    color: white;
    opacity: 1;
    transition: opacity .3s ease-in-out ;

}

.content-container-left > h1{
  color: white !important;
  font-size: 3.2rem;
  text-shadow: 0 2rem 5rem rgb(0, 0, 0);
}

.home-main-image-wrapper{
  width: 100%;
  height: 92%;
  position: relative;
  animation: grayToColor ease-in .9s forwards;
  -webkit-animation: grayToColor ease-in .9s forwards; 
  -moz-animation:  grayToColor ease-in .9s forwards;
  -ms-animation:  grayToColor ease-in .9s forwards;
  background-image: url('./walletImages/marketingBeach.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  will-change: transform;
}

@keyframes grayToColor {
  from {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter:  grayscale(100%);
  }

  to{
    -webkit-filter: grayscale(0%);
    -moz-filter:  grayscale(0%);
    filter: grayscale(0%) brightness(1.2);
  }
}


.content-container-right{
 /* border: 3px solid green; */
 width: 100%;
 height: 100%;
 position: relative;
 background-color: rgb(255, 255, 255);
 position: absolute;
 border-radius: .5rem;
}

.content-container-right > div{
  border-radius: inherit;
}


.overlay{
  height: 92%;
  width: 100%;
  position: absolute;
  /* background-image: linear-gradient(297deg, transparent, var(--main-black)); */
  z-index: 3;
  background-image: linear-gradient(93deg, transparent, var(--main-black));

}



.stars-container-home{
  
  position: fixed;
  bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .4rem;
}

.canvas-wrapper{
  flex: 1;
}

.loader-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loader-content{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-container{
  display: grid;
  max-width: 100vw;
  grid-template-columns: 1fr;
  /* grid-template-rows: 85% 15%; */
  position: relative;
  min-height: 12vh;
  background-color: #fcfdfd;
  border-bottom: .01rem solid rgb(216, 216, 216);
  box-shadow: 1px 3px 5px rgb(206, 206, 206);

}

.menu-text,
.logo{
  color:var(--main-black) !important;
  font-size: 1.4rem;
}

.logo-wrapper{
  flex: 1;
  display: flex;
  justify-content: center;
}

.logo{
  border: .2rem solid var(--main-black);
  /* outline: .27rem solid rgb(226, 226, 226); */
  align-self: center;
  width: 50%;
  aspect-ratio: 1;
  display: flex;
  border-radius: 900rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 600;
  justify-self: center;
  font-size: 1em;
}

.menu-content-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  left: 0;
  /* border: 2px solid red; */
  padding: 1.2rem 2rem;
}

.menu-buttons-container{
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4rem;
  /* border: 2px solid red; */
}



.cart-icon-quantity-wrapper{
  padding: .7rem;
  transition: .300s ease-in;
  width: 2.3rem;
  height: 2.3rem;
  aspect-ratio: 1;
  border: .14rem solid;
}

.change-in-cart{
  background-color: var(--main-blue);
  border-color: rgb(211, 211, 211) !important;
  color: rgb(255, 255, 255) !important;
  text-shadow: 0 .5px .5px rgb(107, 107, 107);
}

.shopping-cart-wrapper{
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: .2rem;
  /* border: 2px solid pink; */
}

.main-system-message-container{
  background-color: rgb(240,240,240);
  color: #444444;
  /* padding: .4rem; */
  display: none;
  font-size: .8rem;
  justify-content: center;
  align-items: center;
  /* display: flex; */
  font-weight: 400;
}

.shop-now-button{
  position: absolute;
  
  bottom: 30%;
  font-size: 1.4rem;
  padding:1rem 10rem;
  background-color:rgb(30,30,30);
  color: white;
  border-top-right-radius: .7rem;
  border-bottom-right-radius: .7rem;
  border: none;
  /* font-weight: 600; */
}



.shop-now-button:hover{
  background-color: black;
}

li{
  color: white;
  list-style: none;
  font-weight: 500;
}

.navigation-wrapper{
  /* border: 1px solid red; */
  flex: 1;
  /* align-self: flex-end; */
}

.nav-list{
  display: flex;
  gap: 5rem;
  /* padding-right: 2rem; */
  /* border: 2px solid red; */
}

.footer-container{
  background-color:var(--main-black);
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 0;
  height: 100%;
  color: white;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: repeat(1, 1fr);
}

.footer-container > .footer-section > h2 {
  text-align: center;
  color: #979797;
}

.footer-section{
  display: grid;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  align-items: center;
  grid-auto-rows: 1fr 5fr;
  height: 100%;
}

.footer-section  > div > form > input{
  width: 100%;
  padding: 1rem;
  padding-left: 1rem;
  background-color: #a7a7a7;
  border: none;
  outline: none;
}

.footer-section  > div > form{
  display: flex;
  gap: .2rem;
}




.shop-page-wrapper{
  max-height: 100vh;
  display: grid;
  grid-template-rows: 1fr 11fr;
}

.shop-main-content{
  display: grid;
  grid-template-columns: 3fr 9fr;
  max-height: 100%;
  position: relative;
  padding-top: 2rem;
}

.displayed-products-container{
  padding: 5rem 10rem 5rem 0;
  height: 100%;
  display: grid;
  grid-template-columns: repeat( 3,1fr);
  gap: 1rem;
}

.product-image-container{
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
}



.product-card-info{
  background-color: rgb(245,245,245);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .2rem;
  padding:.4rem;
  border: .1rem solid rgb(230, 230, 230);

}

.product-type{
  font-size: .68rem;
  color:rgb(90,90,90);
}

.product-name{
  font-weight: 600;
  color: rgb(30,30,30);
  font-size: .95rem;
}

.product-price{
  font-weight: 500;
  font-size: .95rem;
  color: rgb(65,65,65);
}

.shop-side-bar{
  display: flex;
  max-height: 100%;
  flex-direction: column;
  align-items: center;
  gap: .8rem;
}

.product-main-image-container{
  height: 100%;
  position: relative;
}

.shop-now-button-wrapper{
  transition: .14s ease-in-out;
}

.shop-now-button-wrapper:hover{
    transform: scale(1.1);
}

.image-forged{
  transform:scale(.87);
}

.bottom-right{
  gap:1rem;
}

.open-menu-container{
  border-right: .05rem solid rgb(83, 83, 83);
  position: fixed;
  z-index: 10;
  width: 30vw;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: var(--main-black);
  padding: 2rem;
  border-top-right-radius: 1.5rem ;
  border-bottom-right-radius: 1.5rem;
}

.close-menu-icon{
  font-size: 1.6rem; 
  right:2rem;
  color: white;
   position: absolute;
}

.close-order-btn{
  color: var(--title-gray) !important;
}

.close-order-btn:hover{
  color: white !important;
}

.menu-title{
  color:  rgb(253, 253, 253);
  font-size: 3rem;
  font-weight: 500;
}

.nav-item-list{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.menu-item{
  /* padding-bottom:.8rem; */
  padding: 1rem;
  width: 100%;
  display: flex;
  /* border-bottom: .12rem solid rgb(166, 166, 166); */
  color:  rgb(255, 253, 253);
  font-size: 1.2rem;
  text-decoration: none;
}

.menu-item:hover{
  /* background-color: #5e5e5e; */
  color: rgb(160, 160, 160);

}

.nav-wrapper{
  padding: 4rem 1rem;
}

.opened-product-page{
  height: 100vh;
  position: relative;
  display: flex; 
  flex-direction: column; 
}



.opened-product-main-content{
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: 65% 35%;
  
}

.main-content-left{
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  justify-content: center;
  /* z-index: -1; */
}

.displayed-image-container > img{
  width: 90%;
  height: auto;
  margin-left:1.5rem;
  z-index: -1;
  position: relative;
}

.main-content-right{
  padding: 4rem 16rem 2rem 0;
}

.displayed-image-container{
  max-height: fit-content;
}

.product-container{
  background-color: white;
  max-width: 100%; 
  grid-template-columns: 1fr;
  display: grid;
  border:.12rem solid rgb(225, 225, 225);
}

.stars-container-opened-product{
  display: flex;
  flex-direction: row-reverse;
  font-size: .75rem;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: .5rem;
  gap: .4rem;
}


.product-main-image{
  width: 100%;
  position: relative;
}



.product-basic-info{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: .2rem;
  border-bottom: .1rem solid gray;
  margin-bottom: 1rem;
}

.product-title{
  color: rgb(30,30,30) !important;
  font-size: 2.1rem;
}

.product-sub-title{
  color: rgb(140,140,140);
  font-weight: 400;
  font-size: 1rem;
}

.opened-product-price{
  font-size: 1.3rem;
  font-weight: 600;
  justify-self: flex-end;
}

.all-colors-container{
  min-height: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  /* border: 2px solid red; */
}

.add-opened-product-button{
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border-radius: .35rem;
  border: .15rem solid transparent;
  background-color: rgb(30,30,30);
  color: white;
  font-weight: 600;
}
.add-opened-product-button:hover,
.checkout-button:hover{
  background-color: rgb(97, 97, 97);
  background-color: transparent;
  border: .13rem solid rgb(30,30,30);
  color: var(--main-black);
}

.available-color-container{
  border: 1px solid rgb(158, 158, 158);
  width: 2.4rem;
  aspect-ratio: 1;
  border-radius: 900rem;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.available-color{
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 900rem;
}

.extra-info-container{
  margin-top: 1rem;
  color: rgb(48, 48, 48);
}

.features-container{
  min-height: .5rem;
  background: rgb(231, 231, 231);
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.opened-features-container{
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  background: rgb(231, 231, 231);
  width: 100%;
  left: 0;
  padding: 0rem 2rem 1rem;
  display: grid;
  gap:.3rem
}

.extend-features-button{
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.2rem;
}

.extra-info-title{
  font-size: 1rem;
  color: #808080b3;
}

.feature-text{
  font-size: .9rem;
  color: #202020b3;
}

.reviews-wrapper{
  padding: 1rem 0;
  display: grid;
  gap: 1.5rem;
}


.review-container{
  overflow: hidden;
  display: grid;
  border-top: .1rem solid rgb(190, 190, 190);
  border-bottom: .1rem solid rgb(190, 190, 190);
  padding: 3rem 0rem;
  height: 100%;
  align-items: center;
  position: relative;

}

p::first-letter{
  text-transform: capitalize;
}

.author-initials-wrapper{
  background-color: rgb(231, 231, 231);
  color: rgb(31, 31, 31);
  /* border: 2px solid rgb(22,22,22); */
  width: 5rem;
  height: 5rem;
  aspect-ratio: 1;
  font-weight: 500;
  font-size: 1.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 900rem;
}

.author-info-top{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  padding: 0 1rem;

}

.author-info-bottom{
  display: flex;
  height: 100%;
  padding:0 1rem;
  gap: .3rem;
  align-items: center;
  justify-content: center;
}

.author-info{
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-rows: 1fr 1fr;
  width: 100%;
}

.page-select-container{
  display: flex;
  justify-content: center;
  gap: .9rem;
  padding: 1rem 0 2rem 0rem;
}

.page-select-button{
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 900;
  color: var(--main-blue);
}

.open-shopping-cart-container{
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,.5);
  z-index: 10;
}

.opened-cart{
  width: 30%;
  right: 0;
  top: 0;
  height: 100%;
  max-height: 100vh;
  position: inherit;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:.6fr 4.3fr 1.1fr;
}

.cart-title-wrapper{
  background-color: rgb(231, 231, 231);
  padding: 1rem;
  font-size: .85rem;
  color: rgb(51, 51, 51);
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
}

.added-items-container{
  padding: 1rem;
  max-height: 100%;
  overflow-y: scroll;
}

.quantity-cart-item{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 900rem;
  position: absolute;
  border: 1px solid rgb(185, 185, 185);
  right: 0;
  top: 0;
  width: 1.4rem;
  height: 1.4rem;
  font-size: .96rem;
  padding: .62rem;
  aspect-ratio: 1;
  background-color: var(--main-blue);
  color: white;
  font-weight: 700;
  text-shadow: 0px .5px .5px rgb(180, 180, 180);
}

.cart-total-price-container{
  padding: .5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cart-total-price{
  font-weight: 500;
  font-size: 1.1rem;
}

.checkout-button-container{
  border: .1rem solid rgb(221, 221, 221);
  border-left: none;
  border-right: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.checkout-button{
  /* flex: 1; */
  width: 100%;
  /* height: 100%; */
  padding: 1.5rem;
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: .2rem;
  border-radius: .3rem;
  border: none;
  outline: none;
  background-color: rgb(30,30,30);
  color: white;
}

.shopping-cart-icon-wrapper{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .2rem;
}

.close-menu-container{
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 900;
  font-size: 1.3rem;
}

.all-items-cart-container{
  max-height: 100%;
  display: grid;
  gap: 1rem;
}

.all-items-cart-container > div{
  padding: .5rem;

}

.item-in-cart-container{
  border-top: .1rem solid rgb(170,170,170);
  border-bottom: .1rem solid rgb(170,170,170);
  max-height: 10rem;
  display: grid;
  grid-template-columns: 1.5fr 4fr 1.5fr;
}

.in-cart-main-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .2rem;
  padding: 2rem;
}

.in-cart-name{
  font-weight: 800;
  color: rgb(30,30,30);
}

.in-cart-type{
  font-weight: 500;
  font-size: .9rem;
  color: rgb(140, 140, 140);
}

.in-cart-price{
  font-weight: 800;
}

.in-cart-quantity-wrapper{
  display: flex;
  align-items: center;
}

.in-cart-quantity-selector{
  border: .1rem solid rgb(201, 201, 201);
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
}

.in-cart-quantity-selector > div > button{
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(31, 31, 31);
  width: 100%;
}

.in-cart-quantity-text{
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: .9rem;
  justify-content: center;
}

.input-group-container{
  display: flex;
  gap: 0;
}

.checkout-input{
  width: 100%;
  padding: .8rem;
}

input{
  appearance: none;
  border: none;
  border-bottom: .2rem solid rgb(255, 255, 255);
  outline: none;
  border-radius: .2rem;
  background-color: rgb(255, 255, 255);
  border-radius: .25rem;
}


input:focus{
  border-bottom: .2rem solid #1d1d1d;
}

.form-shipping-info-title{
  padding: 0 0.5rem .5rem;
}

.progress-container > div{
  background-color: rgb(175, 175, 175);
  flex: 1;
  display: flex;
  justify-content: center;
  height: .4rem;
  position: relative;
}

.progress-container > div > p{
  position: absolute;
  bottom: -1.5rem;
  font-size: .85rem;
}

.disabled{
  background-color: gray !important;
}

.mobile-message-wrapper{
  display: none;
}

.confirmation-page-container{
  height: 100vh;
  font-size: 1.2rem;
  display: grid;
}

.confirmation-main-content{
  padding: 4rem;
  background-color: rgb(239,239,239);
  margin: 1rem;
}

.order-info-container{
  font-size: 1rem;
  display: grid;
  gap: 1rem;
  color: var(--main-black);
}

.user-info-form{
  display: grid;
}

.user-info-form > input{
  border: none;
  border-radius: .3rem;
  border-bottom: .2rem solid rgb(240, 240, 240);
  outline: none;
  padding: .75rem;
  grid-template-columns: repeat(5, 1fr);
}

.user-info-form > input:focus{
  border-color: black;
}

.payment-form{
  border: var(--border-gray);
  padding: 1rem;
  display: grid;
  gap: 1rem;
}

.payment-form > h2 {
  font-size: 1rem;
  color: gray;
  padding-bottom: .5rem;
  padding-left: .5rem;
}

.payment-form > button {
  padding: .75rem;
  width: 100%;
  background-color: var(--button-green);
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  border-radius: .25rem;
}

.payment-form > button:disabled{
  background-color: rgb(177, 177, 177);
}

.checkout-amount-value{
  font-size: .85rem;
  font-weight: 500;
  color: rgb(145,145,145);
}

.checkout-total-value{
  font-size: 1rem;
  font-weight: 600;
}

.about-page-container{
  color: var(--title-gray);
}

.about-main-info{
  display: grid;
  gap: 1rem;
  /* grid-template-rows: repeat(2, 1fr); */
  /* height: 100%; */
  /* border: 2px solid blue; */
  position: relative;
  padding: 2rem 10vw;
}



.main-info-left{
  font-size: 1.17rem;
  display: flex;
  align-items: center;
  display: grid;
  color: rgb(140, 140, 140);
}

.main-info-right{
  /* border: 3px solid rgb(57, 112, 31); */
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-info-left > p {
  align-items: center;
  align-self: center;
  font-size: .95rem;
  
}

.about-image-wrapper{
  /* border: 2px solid green; */
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.about-image-wrapper > img{
    /* max-width: 30vw; */
    border-radius: .5rem;
    width: 100%;
    border: .4rem solid #0000001c;

}

.about-page-main-content{
/* border: 2px solid red; */
/* s  display: grid; */
  /* gap: 1rem; */
  /* justify-items: center; */
  padding: 5rem 0;
}

.about-page-main-content > div > h1{
  color: var(--main-black);
  font-size: 1.8rem;
  padding-left: 10vw;
  margin: 2rem;
  margin-left: 0;
}

h1{
  color: var(--main-black) !important;
}



.why-buy-container{
  display: grid;
  justify-items: center;
  gap: 1rem;
}

.reasons-why-container{
  display: grid;
  grid-template-columns: repeat( 2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: .4rem;
}

.why-buy-container > h2{
  font-weight: 400;
  padding: 3rem;
  color: var(--title-gray);
  font-size: 1.8rem;
  text-overflow: none;
  white-space: nowrap;
}

/* container w/ reason -> h3 p */
.reasons-why-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: top;
  gap:1rem;
  padding: 1rem;
}

.reasons-why-container > div > p {
  color: rgb(140,140,140);
  font-weight: 500;
  font-size: 1rem;
}

.reasons-why-container > div > h3{
  text-transform: uppercase;
  font-weight: 600;
  font-size: .98rem;
  color: rgb(70, 70, 70);
}

.selected-word-blue{
  color: var(--main-blue);
  font-weight: 900;
  /* background-image: #00ccff; */
  /* background: linear-gradient(45deg, var(--main-blue), rgb(0, 0, 0), var(--main-blue));
  background-clip:text;
  color: transparent; */

}

.custom-icon{
  /* border: .125rem solid var(--main-black); */
  border: .1rem solid rgb(175, 175, 175);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 2.7rem;
  border-radius: 900rem;
  font-size: 1.3rem;
  box-shadow: 0px 2px 2px rgb(228, 228, 228);
}

.react-icon{
  color: var(--main-blue);
  font-size: 1.5em;
}

.admin-login-button{
  background-color: transparent;
  color: rgb(105, 105, 105);
  border: none;
  outline: none;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 3rem;
  font-size: 1.3rem;
}

.login-header-wrapper{
  /* padding: 4rem; */
  display: grid;
  justify-content: center;
  color: black;
}

.login-page-container{
  height: 100vh;
  max-height: 100vh;
  display: grid;
  width: 100vw;
  grid-template-rows: .5fr 5.5fr;
}

.input-wrapper{
  width: 100%;
}

.login-input{
  border: var(--border-gray);
  padding: 1rem;
  width: 100%;
  border-radius: .5rem;
}

.admin-form{
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  justify-self: center;
}

.admin-page-login-button{
  padding: 1rem;
  border: none;
  background-color: var(--main-blue);
  font-size: 1.2rem;
  font-weight: 600;
  width: 100%;
  color: rgb(82, 82, 82);
  border-radius: .5rem;
  color: #fff;
  /* position: absolute; */
  bottom: 0;
}

.admin-home-container{
  /* grid-template-rows: .5fr 6fr; */
  /* max-height: 100vh;
  max-width: 100vw;
  display: grid;
  border: 2px solid green;
  grid-template-rows: .5fr 5.5fr; */
  /* gap: 3rem; */
  /* grid-template-rows: .5fr 2.5fr 2.5fr; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding: 2rem; */
}

.admin-form > h1{
  color: var(--main-black) !important;
}

.admin-form> button{
  background-color: var(--main-black);
}

.admin-form > button:hover {
  opacity: .8;
  color:var(--button-green) ;
}

.admin-sub-header{
  color: var(--main-black) !important;
}



.admin-form-container{
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw !important;
    background-color: white;
    /* display: grid;
    grid-template-rows: .3fr 5.7fr; */
}

.blog-form{
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-template-rows: 5.3fr .7fr;
  /* grid-template-rows: .4fr .4fr 4.2fr .4fr .6fr ; */
}

.form-left{
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(3, .5fr) 4.5fr;
}

.form-right{
  display: grid;
  gap: 1rem;
}

.form-left > input{
  border: 1px solid gray;
  border-radius: .5rem;
  padding: 1rem;
}

textarea {
  border-radius: .5rem;
  padding: 1rem;
  resize: none;
}

.blog-input-container{
  display: flex;
  gap: 1rem;
  border-radius: .5rem;
}

.blog-input-container > input{
  width: 100%;
  padding: 1rem;
  border: var(--border-gray);
}

.blog-form > button {
  border-radius: .5rem;
  border: none;
  outline: none;
  background-color: #00ccff;
  font-size: 1rem;
  font-weight: 600;
  color: rgb(51, 51, 51);
  grid-column-end: -1;
  grid-column-start: 1;
}

.blog-form > button:hover {
    opacity: .5;
    color: var(--main-black);
}

.radio-input{
  border: .125rem solid rgb(87, 87, 87);
  height: 1.2rem;
  width: 1.2rem;
  background-color: rgb(255, 255, 255);
  border-radius: 900rem;
}

.radio-input:checked{
  background-color: rgb(0, 132, 240);
}

.radio-container{
  display: flex;
  align-items: center;
  padding-left: 2rem;
  gap: .5rem;
}

.blogs-page-container{
  min-height: 100vh;
  display: grid;
  /* grid-template-rows: .3fr .3fr 3.9fr 1.4fr; */
  justify-content: center;
  gap: 3rem;
}

.post-data-wrapper{
  /* border: 2px solid sandybrown; */
  position: absolute;
  font-size: .95em;
  gap: .4rem;
  display: flex;
  flex-direction: column;
  color: rgb(219, 219, 219);
  padding: 1.5rem;
  text-shadow: 1px 1px rgb(32, 32, 32);
  z-index: 3;
}

.post-data-wrapper > h3{
  width: max-content;
  padding: .15rem;
}

.blogs-wrapper{
  /* background-color: rgba(0,0,0, .085); */
  padding: 2rem 1rem;
  display: grid;
  margin: 1rem;
  gap: 3rem;
  justify-content: center;
  border-radius: 1rem;
}

.blog-post{
  /* border: .12rem solid rgb(95, 95, 95); */
  aspect-ratio: 1;
  display: grid;
  position: relative;
  grid-template-rows: 4fr 2fr;
  border-radius: .5rem;
  font-size: 1rem;
  border-radius: 90rem;
}

.blog-post:hover > .post-data-wrapper{
  color: white;
}

.blog-post:hover > .blog-post-overlay{
  opacity: .2;
  box-shadow: 0px 3px 30px 10px rgb(19, 19, 19);
}

.blog-post-overlay{
  background-color: black;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: .5;
  border-radius: .7rem;

}

.post-content{
  display: grid;
  bottom: 0;
  width: 100%;
  height: 35%;
  position: absolute;
  background-color: rgba(31, 31, 31, 0.85);
  padding: 1.5rem .5rem;
  align-items: start;
  border-radius: .5rem;
  color: #9b9b9b;
  overflow: hidden;
  display: none;
}

.post-content > p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow:ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  font-weight: 500;
  /* max-height: 100%; */
  font-size: .9em;
  text-align: left;
  text-indent: 5%;
}

.blog-image-wrapper{
  position: relative;
  border-radius: .7rem;

  height: 100%;
}

.blog-image-wrapper >img{
  width: 100%;
  height: auto;
/*   
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem; */
  border-radius: .7rem;
}

.blog-buttons-wrapper{
  position: absolute;
  border-radius: .7rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  bottom: 0;
  justify-content: space-between;
  /* right: 2rem; */
  z-index: 3;
  font-size: 1.4rem;
  color: rgb(216, 216, 216);
  display: flex;
  gap: 1rem;
  background-color: rgb(27, 27, 27);
  padding: 1.5rem 2rem;
  width: 100%;
  opacity: .8;
}

.see-post-button{
  width:30%;
  position: absolute;
  bottom: 0;
  align-self: center;
  padding:.3rem;
  /* background-color:'rgba(255,255,255,.8)'; */
  outline: none;
  background-color: rgba(131, 131, 131, 0.5);
  border: none;
  color: #b3b3b3;
  font-weight: 600;
  
  border-top-right-radius: .4rem;
}

.open-blog-container{
  min-height: 100vh;
  display: grid;
}



.blog-post-left > h2{
  padding-top: 1rem;
  margin-bottom: -5rem;
  color: var(--title-gray);
  font-size: 1.3rem;
}

.blog-post-left > h1 {
  color: var(--main-black) !important;
}



.blog-post-right > h3{
  color: var(--title-gray);
  padding: 2rem;
  /* text-shadow: 1px 1px rgb(124, 124, 124); */
  text-align: center;
}



.open-post-image-wrapper > img {
  border: .12rem solid rgb(196, 196, 196);
}

.post-summary-wrapper{
  padding-top:1rem;
  display: flex;
  flex-direction: column;
  gap: .3rem;
}

.about-label{
  font-weight: 500;
  font-size: .95rem;
  color: var(--title-gray);
  padding-right: .5rem;
}

.open-blog-main-content{
  background-color: rgb(248, 248, 248);
  display: grid;
  margin-top:5rem;
}

.blog-post-right{
  background-color: #e6e6e6;
  justify-self: center;
  margin: .5rem;
  padding: 1rem;
}

.blog-post-left{
  display: grid;
  grid-template-rows: .6fr .6fr 3fr;
  padding: 1rem;
}

.post-info-wrapper > h3{
  text-align: center;
  padding-bottom: 3rem;
  color: var(--title-gray);
}

.post-info-wrapper{
  /* position: fixed; */
  justify-self: center;
  max-height: 100vh;
}

/* .logo-border, */
.logo-text{
  color: var(--title-gray);
  font-size: 1.6em;
  font-weight: 600;
  
}

.logo-container{
  border:.23rem solid var(--title-gray);
  aspect-ratio: 1;
  border-radius: 900rem;
  height: 7rem;
  justify-self: center;
}

.background-cover{
  background-color: black;
  opacity: .5;
  position: fixed;
  height: 150vh;
  width: 150vw;
  z-index: 9;
}

.contact-page{
  min-height: 100vh;
  display: grid;
  justify-content: center;
}

.contact-content{
  display: grid;
  gap: 1.5rem;
  padding: 6rem 2rem;
}

.contact-content > div > p{
  padding: 0 1rem;
  color: rgb(158, 158, 158);
}

.contact-middle > p{
  font-weight: 400;
}

.contact-form{
  /* border: 1px solid blue; */
  gap: 1rem;
  display: grid;
  padding: 1rem;
}

.contact-form > input {
  border: none;
  outline: none;
  padding: 1rem;
  background-color: rgb(224, 224, 224);
}

.contact-form > textarea{
  border: none;
  outline: none;
  padding: 1rem;
  background-color: rgb(224, 224, 224);
  min-height: 30vh;
}

.contact-form > button {
  padding: 1rem;
  background-color: var(--main-blue);
  border-radius: .4rem;
  font-weight: 600;
  border: none;
  outline: none;
  color: white;
}

.contact-section > h1{
  color: var(--title-gray);
  padding-bottom: .5rem;
  font-weight: 700 !important;
}

.contact-section > h2 {
  color: var(--title-gray);
  padding-bottom: .5rem;
  font-weight: 400;
}

.contact-icon{
  margin-right: .6rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(1.3);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: .2rem solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--main-blue) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.new-admin-form-container{
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: rgba(0,0,0,.9);
  display: grid;
  align-items: center;
}

.new-admin-form{
  display: grid;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 70vw;
  gap: .7rem;
  align-self: center;
  position: relative;
}

.new-admin-form > input{
  width: 30vw;
  padding: .9rem;
  background-color: rgb(212, 212, 212);
  outline: none;
  border: none;
}

.new-admin-form > button {
  padding: .9rem;
  border-radius: .3rem;
  outline: none;
  border: none;
  background-color: var(--main-blue);
  font-weight: 600;
  color: var(--main-black);

}

.new-admin-form > h1 {
  color: white;
}

.admin-form-wrapper{
  height: 100%;
  position: absolute;
  width: 100vw;
  display: grid;
  justify-content: center;
  align-items: center;
}

.error-message{
  color: red;
  font-weight: 300;
}

.admin-home-container{
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-rows: .5fr 5.5fr;
  padding:0 0rem 6rem 0rem;
  gap: 5rem;
}

.admin-header{
  display: grid;
  justify-content: center;
  align-items: center;
  border-bottom: .1rem solid rgb(190, 190, 190);
}

.admin-home-container > div{
    height: 100%;
    overflow: hidden;
}

.admin-main-content{
  display: grid;
  height: 100%;
  grid-template-rows: 1.5fr 4.5fr;
  padding:0 3rem;
}

.admin-main-content > div{
  height: 100%;
  overflow: hidden;
}

.orders-preview{
  overflow-y: scroll !important;
  padding: 0rem 0 0rem 1rem;
  position: relative;
  
}

.admin-left{
  position: relative;
}

.admin-right{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  color: rgb(185, 185, 185);
}

.admin-right > div:hover{
  color: var(--main-black);
}

.admin-right > div{
  display: grid;
  justify-content: center;
  align-items: center;
}

.admin-orders-header{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


.order-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .8rem;
  border-bottom: .1rem solid rgb(204, 204, 204);
  font-size: .9rem;
  border-radius: .3rem;
  color: var(--paragraph-gray);
  font-weight: 500;
}

.order-container:hover{
  background-color: rgb(158, 158, 158);
  color: white;
  font-weight: 800;
}

.admin-title{
  color: var(--main-black);
}

.admin-sub-header{
  color: var(--main-blue);
}

.open-order-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
  display: flex;
  justify-content: flex-end;
  overflow-y: scroll;
}

.open-order-container > div {
  /* border: 3px solid rgb(216, 216, 216); */
  background-color:var(--main-black);
  width: 45%;  
}

.open-order{
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  /* border: 5px solid rgb(255, 73, 73) !important; */
  /* padding: 1rem; */
  gap: .3rem;
}

.open-order > div{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  border-bottom: var(--border-gray);
}

.order-field-name{
  font-weight: 500;
  font-size: 1.2rem;
  color: rgb(94, 94, 94);
}

.field-container{
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;
  gap: .5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.order-field-value{
  font-weight: 700;
  color:rgb(197, 197, 197);
}



.text-green{
  color: rgb(49, 207, 96);
}










@media(orientation:landscape){
  .footer-container{
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  .bottom-right{
    width: 70%;
  }

  .review-container{
    font-size: 1rem;
    grid-template-columns: 1fr 5fr 1fr;
  }

  .logo{
    width: 4.5rem;
  }

  .contact-content{
    margin: 0 20vw;
  }

  .admin-form{
    /* border: 2px solid red; */
    max-width: 35vw;
  }

  .reviewPageContainer{
    grid-column-start: 1;
    grid-column-end: -1;
    /* min-height: 100vh; */
    display: grid;
    padding: 5rem;
  }

  .open-blog-main-content{
    display: grid;
    grid-template-columns: 4fr 2fr;
    max-width: 65vw;
    justify-content: center;
    justify-self: center;
    background-color: rgb(248, 248, 248);
    border: .12rem solid rgb(230, 230, 230);
  }

  .open-post-image-wrapper{
    width: 70%;
    display: grid;
    justify-self: center;
    align-items: center;
    height: max-content;
    margin: -1rem;
  }

  .blog-post-right{
    background-color: #e6e6e6;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    gap:1rem;
    border: 1px solid rgb(224, 224, 224);
    position: relative;
    padding: 3rem 1rem;
    margin: 0;
  }
  
  .blog-post-left{
    display: grid;
    grid-template-rows: .3fr .3fr 5.4fr;
    padding: 3rem;
  }

  .about-image-wrapper > img{
    max-width: 25vw;
  }

  .about-main-info{
    grid-template-columns: repeat(2, 1fr);
  }

  .main-info-left{
    grid-template-rows: 1fr 5fr;
  }

  .main-info-right{
    justify-content: center;
  }

  .main-info-left > p {
    font-size: 1.1rem;
  }

  .blogs-wrapper{
      grid-template-columns: repeat(3 , 1fr);
  }
  .post-content > p{
    font-size: .85em;
  }

  .blogs-wrapper{
    margin: 0 10rem;
    gap: 5rem;
  }

  .reasons-why-container{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows:repeat(2, 1fr) ;
    gap: 3rem;
  }

  .why-buy-container{
   padding: 0 10vw;
  }

  .why-buy-container > div{
    padding: 4rem;
  }

  .admin-login-button{
    position: absolute;
  }

  .overlay{
    width: 50%;
    right: 0;

  }
  
}

@media (orientation: landscape) and (width < 900px){
  :root{
    font-size: .65rem;
  }

  .mobile-message-wrapper{
    display: none;
  }
}

@media(orientation:portrait) and (width < 295px){
  :root{
    font-size: .8px;
  }

  .content-container{
    padding: 0;
    margin: .6rem;
  }

  .open-menu-container{
    padding: .9rem;
  }
}

@media (orientation: portrait){
    :root{
      font-size: 1.3rem;
    }

    .footer-container{
      gap: 0;
    }

    .add-opened-product-button{
      background-image: linear-gradient(195deg,#ffd088,var(--main-blue));
      border: none;
      outline: none;
      color: var(--main-black);
      font-weight: 900;
      font-size: 1rem;
      border: .1rem solid var(--main-black);
    }

    .reviewPageContainer{
      grid-column-start: 1;
      grid-column-end: -1;
      /* min-height: 100vh; */
      display: grid;
      padding: 1rem;
  }
  

    .content-container{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      margin: 1rem;
      overflow: hidden;
      position: relative;
    }

    .main-system-message-container{
      font-size: .7rem;
      font-weight: 500;
    }

    .home-menu-wrapper{
      position: relative;
    }
    

    .content-container-left{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      background-color: transparent;
      align-items: center;
      gap: 25vh;
    }

    .content-container-left > button {
      opacity: 1;
      position: relative;
      width: 72vw;
      margin-bottom: -3rem;
    }
    

    .content-container-left > h1{
      font-size: 1.8rem;
      width: 100vw;
      position: relative;
      text-align: center;
      /* margin-top: -2rem; */
    }

    .overlay{
      /* display: none; */
      height: 40%;
      width: 100%;
      background-image: linear-gradient(360deg, transparent, var(--main-black));

    }

    .menu-buttons-container > a{
      display: none;

    }

    .mobile-message-wrapper{
      color: white;
      font-size: 1.3rem;
      display: grid;
      justify-items: center;
      gap: 1rem;
      font-weight: 600;
      letter-spacing: .09rem;
      text-shadow: 0 0 .05rem rgb(97, 97, 97);
    }

    .mobile-home-text{
      font-size: 2.1rem;
      font-weight: 900;
      color: white;
      text-align:center;
    }

    .stars-container-home{
      display: none;
    }

    .shop-now-button-wrapper{
      background-color: white !important;
      color: rgb(45,45,45) !important;
      width: 60vw;
      border-radius: .5rem;
    }

    .home-shop-now-btn{
      color:rgb(45,45,45) !important;
      font-weight: 600 !important;
      font-size: 1.3rem !important;
    }
    
    .menu-container{
      display: grid;
      /* position: sticky;
      top: 0; */
    }

    .open-menu-container{
      width: 100vw;
      height: 100vh;
      border-radius: 0;
    }

    /* SHOPS PAGE STYLES MOBILE */

    .shop-main-content{
      display: grid !important;
      grid-template-columns: 1fr;
      grid-template-rows: .6fr 5.4fr ;
    }


    .shop-main-content,
    .displayed-products-container,
    .shop-side-bar{
      padding: .5rem !important;
      height: 100% !important;
    }

    .displayed-products-container{
      grid-template-columns: 1fr 1fr;
    }

    .product-container{
      /* aspect-ratio: 1; */
    }

    /* OPENED PRODUCT MOBILE */

    .opened-product-main-content{
      grid-template-columns: 1fr;
    }
    .main-content-left {
      max-height: fit-content;
    }

    .main-content-right {
      padding: 1rem;
    }


    .author-info-top{
      gap: .5rem;
      padding: 0;
      display: flex;
      flex-direction: column;
      font-size: 1rem;

    }

    .author-initials-wrapper{
      height: 3em;
      width: 3em;
      font-size: 1rem;

    }

    .author-initials-wrapper > p{
      font-size: .5em !important;
    }

    .opened-cart{
      width: 100vw;
      grid-template-rows: .6fr 4.3fr 1.1fr
    }

    .checkout-header-container{
      display: grid !important;
      width: 100vw !important;
      justify-items: center;
      justify-content: center !important;
      position: sticky;
      top: 0;
      background-color: white;
      box-shadow: 0px 0px 1rem .1rem rgb(182, 182, 182);
    }

    .checkout-progress-container{
      width: 80vw !important;
      padding-bottom: 1rem;
    }

    .checkout-main-content{
      grid-template-columns: 1fr !important;
      width: 100vw !important;
      display: flex !important;
      flex-direction: column-reverse;
      justify-content: flex-end !important;
      position: relative !important;
    }

      .reviewContentContainer{
       display: flex;
      justify-content: flex-end; 
      padding: 2rem;
      }
      
  
      .review-container{
          padding: 1rem 0;
          gap: 1.5rem;
      }

}

@media(orientation: portrait) and (width < 750px){
  :root{
    font-size: .85rem;
  }
}


